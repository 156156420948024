import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export default class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <footer>
                <div className="text-center text-white">
                    <p>
                        suporte@horasol.com | (16) 3322-5933<br />
                        <a className="text-white" href="http://www.horasol.com" target="_blank" rel="noopener noreferrer"> www.horasol.com </a><br />
                        <span>CNPJ: 50.399.542/0001-57</span>
                    </p>
                </div>
                <div className="text-center text-white">
                    <span> © 2020 Copyright - Todos os direitos reservados </span> | <Link to = "/Privacidade" className="text-white"  href="#">Política de Privacidade</Link>
                </div>
            </footer>
        );
    }
}