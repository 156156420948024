import React, { Component } from 'react';
import { FaGooglePlay } from "react-icons/fa";
import imagemHorasol from '../img/imagemHorasol.jpg';
import colaboradores from '../img/colaboradores.jpg';

import logoMarcaPonto from '../img/MarcaPonto/logoMarcaPonto.png';
import loginMarcaPonto from '../img/MarcaPonto/loginMarcaPonto.jpg';
import indexMarcaPonto from '../img/MarcaPonto/indexMarcaPonto.jpg';
import modalMarcaPonto from '../img/MarcaPonto/modalMarcaPonto.jpg';
import historicoMarcaPonto from '../img/MarcaPonto/historicoMarcaPonto.jpg';

import logoHSRegister from '../img/Register/logoHSRegister.png';
import adicionaColabradorRegister from '../img/Register/adicionaColabradorRegister.jpg';
import adicionaEmpresaRegister from '../img/Register/adicionaEmpresaRegister.jpg';
import importacaoRegister from '../img/Register/importacaoRegister.jpg';
import loginRegister from '../img/Register/loginRegister.jpg';
import marcacoesRegister from '../img/Register/marcacoesRegister.jpg';
import modalRegister from '../img/Register/modalRegister.jpg';
import imagemMarcacaoRegister from '../img/Register/imagemMarcacaoRegister.jpg';

import logoHSMobile from '../img/Mobile/logoHSMobile.png';
import aguardandoLocalizacaoMobile from '../img/Mobile/aguardandoLocalizacaoMobile.jpg';
import historicoMobile from '../img/Mobile/historicoMobile.jpg';
import historicoSincronizarMobile from '../img/Mobile/historicoSincronizarMobile.jpg';
import indexMobile from '../img/Mobile/indexMobile.jpg';
import localizacaoMobile from '../img/Mobile/localizacaoMobile.jpg';
import loginMobile from '../img/Mobile/loginMobile.jpg';
import macacaoOffMobile from '../img/Mobile/macacaoOffMobile.jpg';
import macacaoOnMobile from '../img/Mobile/macacaoOnMobile.jpg';
import macacaoSincronizada from '../img/Mobile/macacaoSincronizada.jpg';
import modalSair from '../img/Mobile/modalSair.jpg';
import permissaoCameraMobile from '../img/Mobile/permissaoCameraMobile.jpg';
import permissaoLocalMobile from '../img/Mobile/permissaoLocalMobile.jpg';
import splashscreenMobile from '../img/Mobile/splashscreenMobile.jpg';

import logoHSEnterprise from '../img/Enterprise/logoHSEnterprise.png';
import cadastroColaboradorEnterprise from '../img/Enterprise/cadastroColaboradorEnterprise.jpg';
import cadastroHorarioEnterprise from '../img/Enterprise/cadastroHorarioEnterprise.jpg';
import importacaoEnterprise from '../img/Enterprise/importacaoEnterprise.jpg';
import indexEnterprise from '../img/Enterprise/indexEnterprise.jpg';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default class Body extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="margemTop">
                <div className="row"> {/* Row HS */}
                    <div className="col-lg-8">
                        <h1>Conheça nossa Empresa</h1>
                        <div className="margemTextos">
                            <h2>HORA SOL TECNOLOGIA</h2>
                            <p>
                                A Empresa com tecnologia de ponto e acesso que moderniza a região de Araraquara há mais de 45 anos.
                            </p>
                            <p>Primeira representante DIMEP do interior paulista e líder no segmento de relógios de ponto.</p>
                        </div>
                        <p><a className="btn btn-secondary" href="http://www.horasol.com/empresa/" target="_blank" rel="noopener noreferrer">Ver mais</a></p>
                    </div>
                    <div className="col-lg-4">
                        <img className="img-fluid mx-auto" alt="Imagem" src={colaboradores} />
                    </div>
                </div>
                <hr />

                <div className="row margemTop">{/* Row Logos */}
                    <div className="col-lg-3">
                        <img className="tamanhoLogo" alt="img" src={logoMarcaPonto}></img>
                        <h2>Marca Ponto WEB</h2>
                        <p className="alinhamentoBotao">Sistema desenvolvido para garantir a facilidade de marcar o ponto em qualquer lugar fazendo uso de um dispositivo web.</p>
                        <p><a className="btn btn-secondary" href="#MarcaPontoWeb">Detalhes</a></p>
                    </div>
                    <div className="col-lg-3">
                        <img className="tamanhoLogo" alt="img" src={logoHSRegister}></img>
                        <h2>HSRegister</h2>
                        <p className="alinhamentoBotao">Sistema para registro de colaboradores para o uso dos sistemas Marca Ponto Web e HSMobile.</p>
                        <p><a className="btn btn-secondary" href="#HSRegister">Detalhes</a></p>
                    </div>
                    <div className="col-lg-3">
                        <img width = "100" height = "100" alt="img" src={logoHSEnterprise}></img>
                        <h2>HSEnterprise </h2>
                        <p className="alinhamentoBotao">Sistema responsável pelo tratamento de ponto.</p>
                        <p><a className="btn btn-secondary" href="#HSEnterprise">Detalhes</a></p>
                    </div>
                    <div className="col-lg-3">
                        <img className="tamanhoLogo" alt="img" src={logoHSMobile}></img>
                        <h2>HSMobile </h2>
                        <p className="alinhamentoBotao">Aplicativo desenvolvido com a finalidade de promover a marcação de ponto via dispositivos mobile.</p>
                        <p><a className="btn btn-secondary" href="#HSMobile">Detalhes</a></p>
                    </div>
                </div>
                <hr id="MarcaPontoWeb" />

                <div className="row margemTop"> {/* Row Marca Ponto Web */}
                    <div className="col-12 col-sm-3 col-md-6">
                        <Carousel showThumbs={false} autoPlay infiniteLoop showStatus={false}>
                            <div>
                                <img src={loginMarcaPonto} alt="Tela de Login do Marca Ponto Web" />
                            </div>
                            <div>
                                <img src={indexMarcaPonto} alt="Tela inicial do Marca Ponto Web" />
                            </div>
                            <div>
                                <img src={modalMarcaPonto} alt="Modal de sucesso após marcação" />
                            </div>
                            <div>
                                <img src={historicoMarcaPonto} alt="Tela de Histórico de marcação efetuadas no ultimos 2 dias" />
                            </div>
                        </Carousel>
                    </div>
                    <div className="col">
                        <h2>Marca Ponto WEB</h2>
                        <p>Marcação de Ponto em qualquer lugar e à qualquer hora!</p>
                        <p>
                            Com esse sistema você tem a possibilidade de fotografar e obter sua localização atual viabilizando a ideia de
                            marcação de ponto externa evitando problemas com horarios e localização. Você ainda pode conferir suas marcações
                            de ponto efetuadas nos ultimos 2 dias garantindo a confiabidade e a eficiencia da marcação de ponto externa.
                        </p>
                        <p><a className="btn btn-secondary" href="https://marcapontoweb.hssistema.com/" target="_blank" rel="noopener noreferrer">Confira</a></p>
                    </div>
                </div>
                <hr id="HSRegister" />

                <div className="row margemTop"> {/* Row Register */}
                    <div className="col">
                        <h2>HSRegister</h2>
                        <p>Administre sua empresa sem sair de casa!</p>
                        <p>
                            Nesse sistema você tem a possibilidade de fazer com total autonomia o cadastramento
                            e a manutenção das informações de Usuários, Colaboradores e Empresas. Podendo ainda escolher
                            suas atividades, como gerar relatório parciais de um ou mais Colaboradores e conferir a
                            marcação de ponto dos mesmos, de acordo com a Empresa selecionada.
                            Você ainda tem a opção de facilitar o cadastramento de seus Colaboradores utilizando a
                            opção de importação de Colaboradores, onde é feito um upload de um arquivo de tipo texto
                            com os dados necessários dos Colaboradores para o cadastramento, facilitando suas tarefas e
                            otimizando seu tempo.
                        </p>
                        <p><a className="btn btn-secondary" href="https://register.hssistema.com/" target="_blank" rel="noopener noreferrer">Confira</a></p>

                    </div>
                    <div className="col-12 col-sm-3 col-md-6">
                        <Carousel showThumbs={false} autoPlay infiniteLoop showStatus={false}>
                            <div>
                                <img src={loginRegister} alt="Tela de Login do HS Register" />
                            </div>
                            <div>
                                <img src={adicionaEmpresaRegister} alt="Tela de cadastramento de Empresa" />
                            </div>
                            <div>
                                <img src={modalRegister} alt="Tela com modal para confimação de saida do sistema" />
                            </div>
                            <div>
                                <img src={adicionaColabradorRegister} alt="Tela de cadastramento de Colaborador" />
                            </div>
                            <div>
                                <img src={importacaoRegister} alt="Tela de importação de Colaboração" />
                            </div>
                            <div>
                                <img src={marcacoesRegister} alt="Tela de registro de marcações" />
                            </div>
                            <div>
                                <img src={imagemMarcacaoRegister} alt="Tela de exibição da imagem da marcação selecionada" />
                            </div>
                        </Carousel>
                    </div>
                </div>
                <hr id="HSEnterprise" />

                <div className="row margemTop">  {/* Row Enterprise */}
                    <div className="col-12 col-sm-3 col-md-6">
                        <Carousel showThumbs={false} autoPlay infiniteLoop showStatus={false}>
                            <div>
                                <img src={indexEnterprise} alt="Tela inicial do HS Enterprise" />
                            </div>
                            <div>
                                <img src={cadastroColaboradorEnterprise} alt="Tela de cadastro de Colaborador" />
                            </div>
                            <div>
                                <img src={cadastroHorarioEnterprise} alt="Tela de cadastro de Horario" />
                            </div>
                            <div>
                                <img src={importacaoEnterprise} alt="Tela de importação de marcação" />
                            </div>
                        </Carousel>
                    </div>
                    <div className="col">
                        <h2>HSEnterprise</h2>
                        <p>Agora o tratamento de Ponto ficou ainda mais fácil!</p>
                        <p>
                            O HSEnterprise foi desenvovlvido para garantir que você consiga realizar o tratamento de
                            Ponto de forma rapida e fácil, pois ele possibilita o cadastramento de Colaboradores, Empresas,
                            Horários(Hora extra, Horario Noturno e Banco de Horas). E para garantir a eficiencia total à você
                            disponibilizamos as opções para gerar relatórios de Espelho de ponto, Portaria 1510, ACJEF e AFDT,
                            deixando seu tratamento de ponto cada vez mais completo.
                        </p>
                        <p>Para mais informações entre em contato com nossos vendedores:<br/>(16) 3322-5933 </p>
                    </div>
                </div>
                <hr id="HSMobile" />

                <div className="row margemTop">  {/* Row HS Mobile */}
                    <div className="col-12 col-sm-8">
                        <h2>HS Mobile</h2>
                        <p>Marcação de ponto na palma da sua mão!</p>
                        <p>
                            Com esse app você tem a possibilidade de tirar foto e obter sua localização atual para sua marcação
                            de ponto externa com apenas alguns cliques na tela do seu celular.
                            Pensando na suas necessidades temos a opção da marcação offline para o caso de falta
                            de conexão com a internet que é sincronizada com o banco de dados posteriormente
                            quando ha conexão com internet com apenas um clique, garantindo o proposito da aplicação.
                            E para suprir totalmente as exigencias da marcação de ponto você também tem acesso ao historico
                            de suas marcações dos ultimos dois dias provando a eficiencia da marcação de ponto externa.
                        </p>
                        <p><FaGooglePlay />&nbsp;&nbsp;Disponível na PlayStore</p>
                        <p><a className="btn btn-secondary" href="https://play.google.com/store/apps/details?id=com.hsmobilehorasol" target="_blank" rel="noopener noreferrer">Confira</a></p>
                    </div>
                    <div className="col-12 col-sm-3 col-md-3 text-center">
                        <Carousel showThumbs={false} autoPlay infiniteLoop showStatus={false} showIndicators={false}>
                            <div>
                                <img src={splashscreenMobile} alt="splashscreen" />
                            </div>
                            <div>
                                <img src={loginMobile} alt="Tela de Login do HS Mobile" />
                            </div>
                            <div>
                                <img src={permissaoLocalMobile} alt="Tela de permissão de localização" />
                            </div>
                            <div>
                                <img src={indexMobile} alt="Tela inicial do HS Mobile" />
                            </div>
                            <div>
                                <img src={aguardandoLocalizacaoMobile} alt="Tela com mensagem para aguardar a localização" />
                            </div>
                            <div>
                                <img src={localizacaoMobile} alt="Tela com localização atual do usuário" />
                            </div>
                            <div>
                                <img src={permissaoCameraMobile} alt="Tela de permissão de uso da camera" />
                            </div>
                            <div>
                                <img src={macacaoOnMobile} alt="Tela de sucesso após marcação online" />
                            </div>
                            <div>
                                <img src={macacaoOffMobile} alt="Tela de sucesso após marcação offline" />
                            </div>
                            <div>
                                <img src={historicoMobile} alt="Tela de histórico de marcações online" />
                            </div>
                            <div>
                                <img src={historicoSincronizarMobile} alt="Tela de sincronização de marcações offline" />
                            </div>
                            <div>
                                <img src={macacaoSincronizada} alt="Tela de sucesso após a sincronização das marcações offline" />
                            </div>
                            <div>
                                <img src={modalSair} alt="Tela com modal de confirmação de saida do aplicativo" />
                            </div>
                        </Carousel>
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}