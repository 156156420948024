import React, { Component } from 'react';
import { FaShoppingCart } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { FaUserCog } from "react-icons/fa";


export default class Contato extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Nome: '',
            // Email: '',
            // Comentario: ''
        }
        // this.atribuiNome = this.atribuiNome.bind(this);
        // this.atribuiEmail = this.atribuiEmail.bind(this);
        // this.atribuiComentario = this.atribuiComentario.bind(this);
        // this.enviaComentario = this.enviaComentario.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    // atribuiNome(event) {
    //     this.setState({ Nome: event.target.value });
    // }

    // atribuiEmail(event) {
    //     this.setState({ Email: event.target.value });
    // }

    // atribuiComentario(event) {
    //     this.setState({ Comentario: event.target.value });
    // }

    // enviaComentario() {

    // }

    render() {
        return (
            <div className="margemTop">
                <h2 className="text-center">Fale Conosco</h2>
                <div className="expansaoDiv">
                    <div className="row justify-content-center margemTop expansaoDiv">
                        <div className="col-12 col-sm-3 margem">
                            <div className="margemTop">
                                <FaUserFriends size={25} />
                            </div>
                            <div className="margemTop">
                                <h5>RH</h5>
                                <p>Telefone: (16) 3322-5933<br />Ramal: 4004, 4005 e 4006 </p>
                                <p>E-mail:<br />adm01@horasol.com<br />adm02@horasol.com<br />adm03@horasol.com</p>
                            </div>
                        </div>

                        <div className="col-12 col-sm-3 margem">
                            <div className="margemTop">
                                <FaUserCog size={25} />
                            </div>
                            <div className="margemTop">
                                <h5>Suporte </h5>
                                <p>Telefone: (16) 3322-5933<br />Ramal: 4007, 4008 e 4009 </p>
                                <p>E-mail:<br />suporte@horasol.com </p>
                            </div>
                        </div>

                        <div className="col-12 col-sm-3 margem">
                            <div className="margemTop">
                                <FaShoppingCart size={25} className="teste" />
                            </div>
                            <div className="margemTop">
                                <h5>Vendas</h5>
                                <p>Telefone: (16) 3322-5933<br />Ramal: 4015</p>
                                <p>E-mail:<br />ederson@horasol.com<br /> roberto@horasol.com</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
            </div>
        );
    }
}

{/* <div className="card text-dark col-10 col-sm-6 tamanhoHeaderCard">
                        <div className="card-header">
                            <h5 className="card-title">Deixe sua mensagem</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.enviaComentario}>
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <label htmlFor="Nome">Nome:</label>
                                        <input
                                            className='form-control'
                                            type="text"
                                            id="Nome"
                                            name="Nome"
                                            value={this.state.Nome}
                                            onChange={this.atribuiNome}
                                            required="required"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <label htmlFor="Email">E-mail:</label>
                                        <input
                                            className='form-control'
                                            type="email"
                                            id="Email"
                                            name="Email"
                                            value={this.state.Email}
                                            onChange={this.atribuiEmail}
                                            required="required"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <label htmlFor="comentario">Comentário:</label>
                                        <textarea
                                            className="form-control resizeComentario"
                                            id="comentario"
                                            rows="5"
                                            value={this.state.Comentario}
                                            onChange={this.atribuiComentario}
                                            required="required"
                                            autoComplete="off">
                                        </textarea>
                                    </div>
                                </div>
                            </form>
                            <button type="submit" className="btn btn-secondary float-right">Enviar</button>
                        </div>
                    </div> */}