import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import logoHoraSol from '../img/logoHoraSol.png';

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <header>
                <Navbar expand="md" className="NavBar" light>
                    <NavbarBrand href="/" className="corLink">
                        <img className="img-fluid mx-auto" width="100" height="100" alt="Imagem" src={logoHoraSol} />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="mx-auto aumentaLetra" navbar>
                            <NavItem className="margemNavlink">
                                <Link to="/" className="text-light" onClick={this.toggle}>Home</Link>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar className="text-light">
                                <DropdownToggle nav caret className="corLink margemNavlink">
                                    Sistemas
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="https://register.hssistema.com/" target="_blank" rel="noopener noreferrer">
                                        HS Register
                                    </DropdownItem>
                                    <DropdownItem href="https://marcapontoweb.hssistema.com/" target="_blank" rel="noopener noreferrer">
                                        Marca Ponto Web
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem className="margemNavlink">
                                <Link to="/Contato" className="text-light" onClick={this.toggle}>Contato</Link>
                            </NavItem>
                            <NavItem className="margemNavlink">
                                <Link to="/Privacidade" className="text-light" onClick={this.toggle}>Política</Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}