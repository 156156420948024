import React, { Component } from 'react';
import { FaAngleDoubleUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip'

export default class Privacidade extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mostraVoltar: 0
        }
        this.topoPagina = this.topoPagina.bind(this);
        this.mostraBotaoVoltar = this.mostraBotaoVoltar.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.mostraBotaoVoltar);
    }

    mostraBotaoVoltar() {
        this.setState({ mostraVoltar: this.state.mostraVoltar + 1 })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.mostraBotaoVoltar);
    }

    topoPagina() {
        window.scrollTo(0, 0);
        this.setState({ mostraVoltar: 0 })
    }

    render() {
        let botaoTopo =
            this.state.mostraVoltar > 1 ?
                <div className="col-1 text-right">
                    <button className="btn btn-light botaoTopo" data-tip="Voltar ao Topo" onClick={this.topoPagina}><FaAngleDoubleUp /></button>
                    <ReactTooltip place="top" type="dark" effect="solid" />
                </div>
                :
                null;
        return (
            <div className="margemTop alinhamentoText">
                <div className="row">
                    <div className="col-12 col-sm-4 col-md-3 bordaLateral">
                        <ol>
                            <a className="text-light" href="#1"><li>GENERALIDADES</li></a>
                            <a className="text-light" href="#2"><li>ADVERTÊNCIA</li></a>
                            <a className="text-light" href="#3"><li>TRATAMENTO DE DADOS</li></a>
                            <a className="text-light" href="#4"><li>MODIFICAÇÕES DA POLÍTICA DE PRIVACIDADE</li></a>
                            <a className="text-light" href="#5"><li>DISPOSIÇÕES FINAIS</li></a>
                        </ol>
                    </div>
                    <div className="col-10 col-sm-7">
                        <h2 className="text-center">Politica de Privacidade</h2>
                        <div>
                            <div className="margemTextos" >
                                <ol>
                                    <h5><li id="1">GENERALIDADES</li></h5>
                                    <dl>
                                        <dd>
                                            CLAUSULA 1. A Política de Privacidade da HORA SOL TECNOLOGIA garante segurança e privacidade dos dados pessoais nas transmissões dessas informações com o uso de (TLS/SSL). Lembrando que esses dados serão utilizados apenas no sentido de tornar a experiência com o nosso sistema e plataforma de dados via aplicativo a mais produtiva e agradável possível.
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dd>
                                            CLAUSULA 2. A plataforma / Aplicativo / Sistema / Site, pertencente à HORA SOL TECNOLOGIA que adota os níveis legalmente requeridos quanto à segurança na proteção de dados elencados pela LGPD – Lei Geral de Proteção de Dados – Lei 13.709/2018, com respectivo tratamento de informação e utilização de todos, com todos os meios e medidas técnicas para inibição da perda, mau uso, alteração, acesso não autorizado ou subtração indevida dos dados pessoais recolhidos
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dd>CLAUSULA 3. A presente empresa reserva-se ao direito de modificar a Política de Privacidade para adaptá-la a alterações legislativas ou jurisprudenciais, ou àquelas relativas às práticas comerciais. Em qualquer caso, as mudanças serão anunciadas por meio do site da empresa HORA SOL TECNOLOGIA. <a href="http://www.horasol.com/">(http://www.horasol.com/)</a> sendo as mudanças introduzidas com uma antecedência razoável à sua colocação em prática como nova cientificação e assinatura do novo termo em modelo de aditivo ou novo regramento integral.</dd>
                                    </dl>
                                    <dl>
                                        <dd>CLAUSULA 4. A plataforma da WEB ou o aplicativo de dispositivo móvel poderão fornecer acesso a links para outros sites externos cujos conteúdos e Políticas de Privacidade, bem como segurança da informação, não são de responsabilidade da HORA SOL TECNOLOGIA. Por isso, recomenda-se aos usuários que, ao serem redirecionados para sites externos, consultem sempre as respectivas Políticas de Privacidade antes de fornecerem seus dados.
                                            <ul>
                                                <li>4.1. A utilização dos serviços disponibilizados pela HORA SOL TECNOLOGIA por qualquer usuário implicará em expressa aceitação quanto aos termos e condições da Política de Privacidade vigente na data de sua utilização.</li>
                                                <li>4.2. Recomendamos àqueles usuários que não concordem com a Política de Privacidade vigente, a não utilização dos serviços da HORA SOL TECNOLOGIA, visto que a sua não aceitação por parte do cliente e usuários ou ainda, a não disponibilização das informações solicitadas, pode impedir a prestação de tais serviços.</li>
                                                <li>4.3. Ressaltamos que novos serviços online, disponibilizados pela HORA SOL TECNOLOGIA, estarão automaticamente sujeitos à Política de Privacidade vigente à época de sua utilização.</li>
                                            </ul>
                                        </dd>
                                    </dl>

                                    <hr id="2" />

                                    <h5><li>ADVERTÊNCIA</li></h5>
                                    <dl>
                                        <dd>
                                            CLAUSULA 5. A navegação, o registro e o uso do software HORA SOL TECNOLOGIA depende da aceitação das condições previstas nesta política.
                                        </dd>
                                    </dl>

                                    <hr id="3" />

                                    <h5><li>TRATAMENTO DE DADOS</li></h5>
                                    <dl>
                                        <dd>
                                            CLAUSULA 6. Com o seu consentimento, o HORA SOL TECNOLOGIA pode recolher, entre outros, os seguintes dados pessoais dos Usuários que sejam pessoas físicas, empregados, necessários as ferramentas de controle de jornada:
                                            <ul>
                                                <li>Nome;</li>
                                                <li> E-mail;</li>
                                                <li> Matrícula;</li>
                                                <li> Número de telefone ou celular;</li>
                                                <li> Localização;</li>
                                                <li> Número do Cadastro de Pessoas Físicas – CPF/MF;</li>
                                                <li> Data de nascimento;</li>
                                                <li> Numero do PIS;</li>
                                                <li> Número da Carteira de Trabalho;</li>
                                                <li> Acesso a galeria de Fotos para o controle de jornada mediante identificação por face;</li>
                                                <li> Filiação - Nome da mãe;</li>
                                            </ul>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dd>
                                            CLAUSULA 7. Com o seu consentimento, a HORA SOL TECNOLOGIA pode recolher, entre outros, os seguintes dados pessoais dos Usuários Empregadores – Pessoas Jurídicas:

                                            <ol>
                                                <li>Razão Social ou Denominação:</li>
                                                <li>Nome Fantasia:</li>
                                                <li>Número do Cadastro Nacional de Pessoas Jurídicas – CNPJ/MF:</li>
                                                <li>Sede para fins fiscais:</li>
                                                <li>Nome do representante legal da empresa: -> É necessário? Sendo que ja possuímos o email para contato</li>
                                                <li>Número do Cadastro de Pessoas Físicas - CNPJ do representante legal:</li>
                                                <li>E-mail do representante legal da operação pela empresa</li>
                                            </ol>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dd>
                                            <ul>
                                                <li>7.1. Para utilização dos aplicativos da HORA SOL TECNOLOGIA (“appXXXXX”) de controle de jornada ainda será necessário o consentimento para acesso em seu dispositivo móvel ou WEB quanto a três tipos de autorizações, descritas abaixo, que deverão ser concedidas pelo usuário com a anuência à presente política:</li>
                                                <ul>
                                                    <li>Localização: Necessário para envio de pushs personalizados e funcionamento do localizador do usuário. A HORA SOL TECNOLOGIA pode usar a localização do usuário para reconhecer o local no qual foi registrado o início ou término da jornada de trabalho (“registro de ponto”).</li>
                                                    <li>Telefone: A HORA SOL TECNOLOGIA armazena o nome do telefone e seu ID (IMEI), como descrito acima, apenas para o Usuário conseguir realizar o processo de registro de ponto. Essas informações não são compartilhadas com terceiros.</li>
                                                    <li>Acessar câmera, fotos, mídias e arquivos do seu dispositivo: Necessário para selecionar foto na galeria para inclusão de foto de registro de ponto. Nenhuma informação do seu celular é lida ou armazenada pela HORA SOL TECNOLOGIA.</li>
                                                </ul>
                                            </ul>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dd>CLAUSULA 8. Quando da fase de testes para implementação do sistema WEB / APLICATIVO, a empresa contratante sob sua responsabilidade autoriza desde já a HORA SOL TECNOLOGIA a criar um usuário real de sua base de dados para aperfeiçoar nossos produtos ou serviços ou adéqua-los as necessidades da empresa contratante.</dd>
                                    </dl>
                                    <dl>
                                        <dd>CLAUSULA 9. Os dados pessoais poderão ser utilizados, com o consentimento dos usuários, para emitir relatórios, mapas e notificações aos Usuários / Empregador ou se solicitado pela Autoridade Fiscal ou de Dados Nacional, além do disposto no ponto “COOKIES” na CLAUSULA 11, do presente termo.</dd>
                                        <ul>
                                            <li>9.1. Os termos da Política de Privacidade aqui expostos serão aplicados exclusivamente às informações pessoais, conforme acima definido, que venham a ser disponibilizadas a HORA SOL TECNOLOGIA pelo usuário para a utilização de seus produtos e serviços.</li>
                                            <li>9.2. Por consequência, a Política de Privacidade aqui exposta não será aplicável a qualquer outro serviço que não os disponibilizados pela HORA SOL TECNOLOGIA, incluídos aqueles sites que estejam de alguma forma vinculados ao site da HORA SOL TECNOLOGIA, por meio de links ou quaisquer outros recursos tecnológicos, e, ainda, a quaisquer outros sites que, de qualquer forma, venham a ser conhecidos ou utilizados pela HORA SOL TECNOLOGIA.</li>
                                        </ul>
                                    </dl>
                                    <dl>
                                        <dd>CLAUSULA 10. Os dados serão mantidos pelo HORA SOL TECNOLOGIA durante a vigência da relação com os usuários na plataforma e, posteriormente a isso, apenas dentro do prazo legal estabelecido ou o contratualmente ajustado entre empresa contratante e HORA SOL TECNOLOGIA .(DISCUSÃO DE PRAZO DE ARMAZENAMENTO)</dd>
                                        <ul>
                                            <li>10.1. As informações de caráter dos usuários e clientes dos serviços da HORA SOL TECNOLOGIA, sendo elas: pessoais, de negócios e/ou financeiras, não são divulgadas pela HORA SOL TECNOLOGIA, exceto nas hipóteses expressamente mencionadas neste documento ou autorizadas pela LEI GERAL DE PROTEÇÃO DE DADOS (LEI 13.709/2018) em seu artigo 7º  e incisos II, V, VI, VII, VIII, IX  e X.</li>
                                            <li>10.2. Tais informações são imputadas pelo cliente de forma manual e/ou inptus automatizados e são armazenados, utilizando-se rígidos padrões de sigilo e integridade, bem como controles de acesso físico e lógico, observando-se sempre os mais elevados princípios éticos e legais.</li>
                                            <li>10.3. Em regra os acessos / tratamento de dados são somente utilizados e realizados pelo contratante / empresa que, por sua vez,  solicitou  o controle de jornada pelo sistema da HORA SOL TECNOLOGIA que disponibiliza  tal ferramenta  via WEB / APLICATIVO, não sendo ela – HORA SOL TECNOLOGIA -  a responsável pelo tratamento ou inptus dos dados, mas tão-somente pelo armazenamento dos mesmos.</li>
                                            <li>10.4. Caso o usuário decida fornecer suas informações pessoais, tal ato implicará em expressa autorização (por escrito) para que tais informações sejam utilizadas para o fornecimento dos serviços online, com o propósito definido em documento contratual celebrados entre a HORA SOL TECNOLOGIA e seus clientes, bem como para que tais informações sejam arquivadas.</li>
                                            <li>10.5. Uma vez provido das informações a respeito do usuário e/ou operação, a HORA SOL TECNOLOGIA trata os dados a nível de Software com o objetivo de prover a gestão de ponto eletrônico, o que não é regra e variará de acordo com os serviços contratados. .</li>
                                            <li>10.6. O acesso às informações coletadas e armazenadas pela HORA SOL TECNOLOGIA é restrito aos profissionais autorizados e para uso do contratante / empresa na gestão do controle de jornada e necessário à prestação de seus serviços, sendo limitado o uso para outras finalidades.</li>
                                            <li>10.7. É exigido, também, de toda organização ou indivíduo contratado para a prestação de serviços de apoio, que sejam cumpridas as Políticas de Segurança da Informação e o Código de Ética e Conduta adotado pela HORA SOL TECNOLOGIA.</li>
                                            <li>10.8. A HORA SOL TECNOLOGIA reforça de maneira detalhada que poderá revelar as informações pessoais que tenha recebido, concordando, desde já, o cliente e o usuário com tal revelação, nas seguintes hipóteses:</li>
                                            <ul>
                                                <li>10.8.1. Sempre que estiver obrigado a revelá-las, seja em virtude de dispositivo legal, ato de autoridade competente, ordem ou mandado judicial;</li>
                                                <li>10.8.2. Aos seus parceiros comerciais e/ou prestadores de serviço, a fim de atender à solicitação de serviços efetuada pelos usuários;</li>
                                                <li>10.8.3. Aos órgãos de proteção e defesa do trabalhador e prestadores de serviços autorizados pela HORA SOL TECNOLOGIA a defender seus direitos;</li>
                                                <li>10.8.4. Para outras instituições, desde que dentro dos parâmetros legais estabelecidos para tanto, podendo, nesta hipótese, o usuário, a qualquer tempo, cancelar esta autorização;</li>
                                                <li>10.8.5. Aos parceiros da HORA SOL TECNOLOGIA para prestação dos serviços técnicos.</li>
                                            </ul>
                                        </ul>
                                        <dd>10.9. A HORA SOL TECNOLOGIA trabalha exaustivamente para assegurar que as informações divulgadas para os clientes e/ou usuários sejam verdadeiras e íntegras, contando com controles apurados de monitoramento das informações fornecidas</dd>
                                    </dl>

                                    <hr id="4" />

                                    <h5><li>MODIFICAÇÕES DA POLÍTICA DE PRIVACIDADE</li></h5>
                                    <dl>
                                        <dd>CLAUSULA 18. A qualquer momento, durante a vigência da relação entre a HORA SOL TECNOLOGIA e o usuário, a presente Política de Privacidade poderá ser modificada, com devido aviso prévio. </dd>
                                    </dl>

                                    <hr id="5" />

                                    <h5><li>DISPOSIÇÕES FINAIS</li></h5>
                                    <dl>
                                        <dd>CLAUSULA 19. A HORA SOL TECNOLOGIA assegura que as informações (textos, imagens, sons e/ou aplicativos) contidas nos seus sites estão de acordo com a legislação e normativos que regulam os direitos autorais, marcas e patentes, não sendo permitidas modificações, cópias, reproduções ou quaisquer outras formas de utilização para fins comerciais sem o consentimento prévio e expresso da HORA SOL TECNOLOGIA.</dd>
                                        <dd>CLAUSULA 20. A HORA SOL TECNOLOGIA não se responsabiliza por eventuais danos e/ou problemas decorrentes da demora, interrupção ou bloqueio nas transmissões de dados ocorridos na internet.</dd>
                                        <dd>CLAUSULA 21. As palavras e os termos constantes desta Política de Privacidade, aqui não expressamente definidos, grafados em português ou em qualquer outro idioma, bem como, quaisquer outros de linguagem técnica ou não, que, eventualmente, durante a vigência do presente instrumento, no cumprimento de direitos e obrigações assumidos por ambas as partes, sejam utilizados para identificar a prática de quaisquer atos, deverão ser compreendidos e interpretados em consonância com o conceito internacionalmente consagrado.</dd>
                                        <dd>CLAUSULA 22. Toda e qualquer controvérsia oriunda dos termos expostos na presente Política de Privacidade serão solucionados de acordo com a lei brasileira, sendo competente o foro da cidade de Araraquara,SP, com exclusão de qualquer outro por mais privilegiado que seja, tendo em conta ser esse o local da sede da empresa.</dd>
                                        <dd>CLAUSULA 23. Fica claro, ainda, que utilização de serviços e as ordens comandadas fora do território brasileiro, ou ainda as decorrentes de operações iniciadas no exterior podem estar sujeitas também à legislação e jurisdição das autoridades dos países onde forem comandadas ou iniciadas.</dd>
                                    </dl>

                                    <hr />
                                </ol>
                            </div>
                        </div>
                    </div >
                    {botaoTopo}
                </div >
            </div >
        );
    }
}