import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Contato from './Contato';
import Header from './Header';
import Footer from './Footer';
import Body from "./Body";
import Privacidade from "./Privacidade";

export default function Rotas() {
    return (
        <Router>
            <div>
                <Header />
                <Switch>
                    <Route exact path='/' component={Body} />
                    <Route path='/Contato' component={Contato} />
                    <Route path='/Privacidade' component={Privacidade} />
                    <Route path='*'>
                        <Redirect to='/' />
                    </Route>
                </Switch>
                <Footer />
            </div>
        </Router>
    );
}